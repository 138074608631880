import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import { Col, Row, Container } from "react-bootstrap"
import ContactData from "../components/contactdata"
import TopicList from "../components/topicList"
import CheckText from "../components/textwithcheck"
import Aos from "aos"
import ZapisConsult from "../components/zapisConsultation"
import UfoTaken from "../images/undraw_Taken_if77.svg"

const WrongPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000, once: true })
  }, [])
  return (
    <Layout>
      <Seo
        title="404"
        keywords={[
          "психолог",
          "барселона",
          "степаненко",
          "александр",
          "зависимость",
          "алкоголизм",
          "наркомания",
          "помощь",
        ]}
      />
      <Container fluid="xl">
        <Row>
          {/* photo and text block */}
          <Col xl={8} lg={8} md={12} sm={12} xs={12} className="py-5">
            <Container fluid="lg">
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className="text-white">
                    <h1 className="display-6 uk-heading-divider">
                      Ошибка 404
                      <br />
                      <span style={{ fontWeight: "200" }}>
                        Неверный адрес страницы
                      </span>
                    </h1>
                    <div>
                      <p className="uk-text-meta">
                        Кажется что-то пошло не так! Страница, которую вы
                        запрашиваете, не существует. Возможно она была удалена,
                        или вы набрали неверный адрес. Перейдите на нашу главную
                        страницу и попробуйте найти необходимую вам информацию
                        там.
                      </p>
                      <img
                        src={UfoTaken}
                        alt="vector by unDraw"
                        style={{ width: "100%" }}
                        data-aos="fade-in"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          {/* /* basic topics */}
          <Col xl={4} lg={4} md={12} sm={12} xs={12} className="p-5 bg-primary">
            <TopicList />
          </Col>
        </Row>
      </Container>
      <div style={{ width: "100%" }} className="bg-secondary">
        <Container fluid="xl">
          <Row>
            <Col
              xl={8}
              lg={8}
              md={12}
              sm={12}
              xs={12}
              className="py-5 text-right"
            >
              <ZapisConsult />
            </Col>
            <Col
              xl={4}
              lg={4}
              md={12}
              sm={12}
              xs={12}
              className="bg-primary p-5"
            >
              <ContactData />
            </Col>
          </Row>
        </Container>
      </div>
      <CheckText text="опыт&nbsp;работы 10&nbsp;лет" />
    </Layout>
  )
}

export default WrongPage
